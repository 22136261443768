import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSpinner, faCheckCircle, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useContext, useState, useEffect, useRef } from 'react';
import FileContext  from '../FileContext'; // Assuming FileContext is imported from somewhere
import SmartStreamTempLogos from '../images/SmartStreamTempLogos.svg';
import { useToken } from './useToken';

const Sidebar = ({ setShowRequiredFilesModal }) => {
    const { files, deleteFile } = useContext(FileContext);
    const [fileStatuses, setFileStatuses] = useState({});
    const prevFilesRef = useRef([]);
    const prevFileStatusesRef = useRef({});

    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = process.env.REACT_APP_FILE_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
    const embeddingsUrl = process.env.REACT_APP_EMBEDDING_SERVICE_API_BASE_URL || `https://${hostname}/api/v1/embeddings/`;
    const tokenData = useToken();
    const token = tokenData.accessToken;

    const handleOpenRequiredFilesModal = () => {
        setShowRequiredFilesModal(true);
    };

    useEffect(() => {
        const checkFileStatus = async (file) => {
            try {
                const response = await axios.get(`${embeddingsUrl}checkEtag/${file.etag}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`
                      }
                    });
                setFileStatuses(prevStatuses => ({
                    ...prevStatuses,
                    [file.etag]: response.data.exists
                }));
            } catch (error) {
                console.error('Error checking etag:', error);
            }
        };

        const prevFiles = prevFilesRef.current;
        const prevFileStatuses = prevFileStatusesRef.current;

        files.forEach(file => {
            if (fileStatuses[file.etag] === undefined || fileStatuses[file.etag] === false) {
                if (!prevFiles.includes(file) || prevFileStatuses[file.etag] !== fileStatuses[file.etag]) {
                    checkFileStatus(file);
                }
            }
        });

        prevFilesRef.current = files;
        prevFileStatusesRef.current = fileStatuses;
    }, [files, embeddingsUrl, fileStatuses]);

    return (
        <div>
            <div style={{

                position: 'fixed',
                top: 0,
                left: 0,
                width: files.length > 0 ? '240px' : '0',
                height: 'calc(100% - 100px)', //Where 100 is the Footer.js height + button height
                overflow: 'auto',
                backgroundColor: '#E7F4FF',
                padding: files.length > 0 ? '1em' : '0',
                boxSizing: 'border-box',
                borderTopRightRadius: '16px', // Add rounded corners to the top right
                borderBottomRightRadius: '16px' // Add rounded corners to the bottom right
            }}>
                <img src={SmartStreamTempLogos} alt="SmartStream Logo" style={{ maxWidth: '210px', paddingBottom: '20px'}} />
                {Array.isArray(files) && files.map((file) => (
                    <div key={file.etag}>
                        <div className="filelisting" title={file.name}>
                            <span style={{ marginRight: '6px' }}>
                                {fileStatuses[file.etag] === undefined ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : fileStatuses[file.etag] ? (
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                ) : (
                                    <FontAwesomeIcon icon={faSpinner} />
                                )}
                            </span>
                            <span>
                            {file && file.name
                                ? (file.name.replace(/_\d+\./, '.').length > 18
                                    ? `${file.name.replace(/_\d+\./, '.').slice(0, 18)}...`
                                    : file.name.replace(/_\d+\./, '.')
                                ) : ''}
                            </span>
                            <FontAwesomeIcon
                                className="filesDeleteIcon"
                                icon={faTrashAlt}
                                onClick={() => {
                                    const confirmDelete = window.confirm(`Are you sure you want to delete the file: ${file.name}?`);
                                    if (confirmDelete) {
                                        try {
                                            const response = axios.delete(`${baseUrl}files/${file.name}`,
                                                {
                                                  headers: {
                                                    Authorization: `Bearer ${token}`
                                                  }
                                                });
                                            deleteFile(file.name);
                                        } catch (error) {
                                            console.error('Error deleting file:', error);
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <button className="open-file-list-modal-button" onClick={handleOpenRequiredFilesModal}>
                    <FontAwesomeIcon icon={faAsterisk} /> Required Files
                </button>
            </div>
        </div>
    );
};

export default Sidebar;