// EnvironmentContext.js
import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

export const EnvironmentContextObject = createContext(null);

console.log('EnvironmentContextObject');

const EnvironmentContext = ({ children }) => {
  const [envData, setEnvData] = useState({"auth_client_id": "none"});
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const baseUrl = `${window.location.protocol}//${hostname}${port}`;
  const baseApiUrl = process.env.REACT_APP_AUTH_SERVICE_API_BASE_URL || `${baseUrl}/api/v1/`;
  // const baseApiUrl = `https://cfm-devint.calavista.ai/api/v1/`;

  const handleEnvData = (newData) => {
    setEnvData(newData);
    console.log('Environment state data:', newData);
    setIsLoading(false);
  };

  console.log('Environment context:', envData);

  useEffect(() => {
    try {
      console.log('Getting environment information...');

      // Optionally, fetch user information from your API
      axios.get(`${baseApiUrl}info/env`, {
      }).then(response => {
          const { data } = response;
          console.log('Environment information:', data);
          handleEnvData({ ...data });
      }).catch(error => {
          console.error('Error getting the environment information:', error);
          setIsLoading(false);
      });
    } catch (error) {
        console.error('Environment context acquisition failed:', error);
        setIsLoading(false);
    }
  }, []);

  // Show loading state while fetching data
  if (isLoading) {
    return <div>Loading environment...</div>; // You can replace this with a loading spinner or return null
  }

  return (
    <EnvironmentContextObject.Provider value={envData}>
      {children}
    </EnvironmentContextObject.Provider>
  );
};

export default EnvironmentContext;
