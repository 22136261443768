// TokenChecker.js
import React, { useState, useEffect, createContext } from 'react';
import { jwtDecode } from 'jwt-decode'; // Corrected import
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import AuthConfig from './AuthConfig';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

export const TokenContext = createContext(null);

const TokenChecker = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [tokenData, setTokenData] = useState({});
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const url = process.env.REACT_APP_REDIRECT_URI;
  const baseUrl = url || `${window.location.protocol}//${hostname}${port}`;
  const baseApiUrl = process.env.REACT_APP_AUTH_SERVICE_API_BASE_URL || `${baseUrl}/api/v1/`;
  const { msalConfig, loginRequest, msalInstance } = AuthConfig();

  const handleTokenData = (newData) => {
    setTokenData(newData);
  };

  const acquireToken = async (request) => {
    try {
      // Attempt to acquire token silently
      const response = await instance.acquireTokenSilent(request);
      const accessToken = response.accessToken;

      // Decode token to extract claims
      const decodedToken = jwtDecode(accessToken);
      const { name, oid } = decodedToken;

      handleTokenData({ accessToken, name, oid });

      // Optionally, fetch user information from your API
      axios.get(`${baseApiUrl}users/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).catch(error => {
        console.error('Error getting the user information:', error);
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.warn('Interaction required, redirecting to login...');
        // Trigger interactive authentication
        instance.acquireTokenRedirect(request);
      } else {
        console.error('Token acquisition failed:', error);
      }
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const request = { ...loginRequest, account: accounts[0] };
      acquireToken(request);
    }
  }, []);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (accounts.length > 0) {
        const request = { ...loginRequest, account: accounts[0] };
        acquireToken(request);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleUserInteraction();
      }
    };

    const handleClick = (event) => {
      const interactiveElement = event.target.closest('a, button, span');
      if (interactiveElement) {
        handleUserInteraction();
      }
    };

    window.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [instance, accounts]);

  return (
    <TokenContext.Provider value={tokenData}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenChecker;
