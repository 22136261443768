import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import RequiredFileInput from './RequiredFileInput';
import { useToken } from './useToken';

const RequiredFilesModal = forwardRef(({ showModal, setShowModal, onResultsUpdate, checkForRequiredFiles }, ref) => {
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availableRequiredDocuments, setAvailableRequiredDocuments] = useState({});
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const baseCFMUrl = process.env.REACT_APP_CFM_SERVICE_API_BASE_URL || `https://${hostname}/api/v1/`;
  const tokenData = useToken();
  const token = tokenData.accessToken;


  const loadFileRequirements = async () => {
    try {
      const requiredDocumentsData = await axios.get(`${baseCFMUrl}cfm/getRequiredDocumentsNamesAndDescriptions`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      setRequiredDocuments(requiredDocumentsData.data);
      
      const getRequiredFilesResult = await axios.get(`${baseCFMUrl}cfm/getRequiredFiles/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      setAvailableRequiredDocuments(getRequiredFilesResult.data);
      
      const allRequiredFilesPresent = Object.values(getRequiredFilesResult.data).every(value => value !== null);
      onResultsUpdate(allRequiredFilesPresent);

    } catch (error) {
      console.error('Error loading JSON or sending data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof token !== 'undefined') {
      loadFileRequirements();
    }
  }, [token]);

  useEffect(() => {
    if (showModal) {
      loadFileRequirements();
    }
  }, [showModal]);

  useEffect(() => {
    if (typeof token !== 'undefined') {
      loadFileRequirements();
    }
  }, [checkForRequiredFiles]);

  useImperativeHandle(ref, () => ({
    loadFileRequirements,
  }));

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleUpload = (file) => {
    
  };

  return (
    <div className={`modal ${showModal ? 'show' : ''}`} onClick={handleCloseClick}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Required Files</h2>
        <div className="file-list">
          {requiredDocuments.map((file, index) => (
            <RequiredFileInput
              key={index}
              file={file}
              handleUpload={handleUpload}
              initialUploadedFileName={availableRequiredDocuments[file.type] || undefined}
            />
          ))}
        </div>
        {/* <div>
          <h3>Endpoint Results</h3>
          <pre>{JSON.stringify(availableRequiredDocuments, null, 2)}</pre> {}
        </div> */}
        <button className="modal-close-button" onClick={handleCloseClick}>Close</button>
      </div>
    </div>
  );
});

export default RequiredFilesModal;
