import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import AuthConfig from './Components/AuthConfig';
import App from './App';
import EnvironmentContext from './Components/EnvironmentContext';

const CtxProviderWrapper = () => {

    return (
        <EnvironmentContext>
            <App />
        </EnvironmentContext>
    );
};

export default CtxProviderWrapper;
