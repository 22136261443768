import { PublicClientApplication } from "@azure/msal-browser";
import { EnvironmentContextObject } from './EnvironmentContext';
import { useContext } from 'react';
// import React from 'react';

const AuthConfig = () => {
    const env = useContext(EnvironmentContextObject);
    // Test the config object
    console.log('AuthConfig');
    console.log(env);
    console.log(env.auth_client_id);
    const clientId = env.auth_client_id || "ef8508f0-0146-4df5-a35b-815348506072";

    const hostname = window.location.hostname;
    const tenantName = "aicfm";
    const b2cPolicy = "B2C_1_signin_only";
    const redirectUri = `${window.location.protocol}//${hostname}/`;

    const msalConfig = {
        auth: {
            clientId: clientId,
            authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicy}`,
            redirectUri: redirectUri,
            postLogoutRedirectUri: redirectUri,
            knownAuthorities: [`${tenantName}.b2clogin.com`],
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
        },
        system: {
            allowNativeBroker: true,
        },
    };

    const loginRequest = {
        scopes: ["openid", "profile", "email", 'offline_access', clientId],
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    return { msalConfig, loginRequest, msalInstance };
};

export default AuthConfig;