import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileContext from './FileContext';
import { useToken } from './Components/useToken';

const FileProvider = ({ children }) => {
    const [files, setFiles] = useState([]);

    // Get the current hostname
    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = process.env.REACT_APP_FILE_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
    const tokenData = useToken();
    const token = tokenData.accessToken; 

    useEffect(() => {
    const fetchFiles = async () => { 
        try {
            const response = await axios.get(`${baseUrl}files/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (Array.isArray(response.data.files)) {
                setFiles(response.data.files);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    }

        if (typeof token !== 'undefined') {
            fetchFiles();
        }
    }, [token]);

    const addFile = (file) => {
        setFiles(prevFiles => [...prevFiles, { name: file.filename, etag: file.etag}]);
    }

    const deleteFile = (fileName) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    }

    return (
        <FileContext.Provider value={{ files, addFile, deleteFile }}>
            {children}
        </FileContext.Provider>
    );
}

export default FileProvider;