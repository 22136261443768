import React, { useState, useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import FileUploadStatus from './FileUploadStatus';
import FileContext from '../FileContext';
import { useToken } from './useToken';


const FileUpload = ({ onFileSelect }) => {
    const { addFile } = useContext(FileContext);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [showUploadStatus, setShowUploadStatus] = useState(false);
    const fileInput = useRef(null);
    const tokenData = useToken();
    const token = tokenData.accessToken;

const handleFileInput = e => {
        if (e.target.files.length > 5) {
            alert('You can only upload a maximum of 5 files at a time');
        } else {
            setSelectedFiles(e.target.files);
        } 
    };

useEffect(() => {
    if (selectedFiles.length > 0) {
        uploadFiles(selectedFiles).catch(err => console.log(err));
    }
}, [selectedFiles]);

const handleClick = () => {
    if (fileInput.current) {
        fileInput.current.click();
    }
};

const handleCloseUploadStatus = () => {
    setShowUploadStatus(false);
};

    const uploadFiles = async (selectedFiles) => {
        // Get the current hostname
        const hostname = window.location.hostname;
        const port = window.location.port ? `:${window.location.port}` : '';
        const baseUrl = process.env.REACT_APP_FILE_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
        setShowUploadStatus(true);
        const promises = [];
        const userID = localStorage.getItem('userID');
        [...selectedFiles].forEach((file, i) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('userID', userID);

            const promise = axios.post(`${baseUrl}files/upload/`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(oldUploadProgress => ({
                        ...oldUploadProgress,
                        [i]: percentCompleted,
                    }));
                }
            })
            .then(response => {
                addFile(response.data);
        });
            promises.push(promise);
        });
        try {
        await Promise.all(promises);
    } catch (error) {
        console.error('Error uploading files:', error);
    } finally {
        setShowUploadStatus(false); // Hide the FileUploadStatus component
    }
    setSelectedFiles([]);
    fileInput.current.value = '';
    };

    return (
        <div>
            <input type="file" multiple ref={fileInput} onChange={handleFileInput} style={{ display: 'none' }} />
            <FontAwesomeIcon icon={faUpload} style={{ cursor: 'pointer' }} onClick={handleClick} />
            <FileUploadStatus
                selectedFiles={selectedFiles}
                uploadProgress={uploadProgress}
                show={showUploadStatus}
                handleClose={handleCloseUploadStatus}
            />
                    </div>
    );
};

export default FileUpload;